import React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
const data = [
  {name:'Sussy', age:10, weight:95},
  {name:'Tony', age:30, weight:60},
  {name:'Giovi', age:15, weight:130},
  {name:'Lei', age:2, weight:24},
]


const Elementosimplechart = () => {
  return (
    <ResponsiveContainer width='100%' aspect={2}>
      <BarChart data={data} width={500} height={300} margin={{top:5, right:30,left:20,bottom:5}}>
        <CartesianGrid strokeDasharray='4 1 2'>  </CartesianGrid>
          <XAxis dataKey='name'></XAxis>
          <YAxis ></YAxis>
          <Tooltip>'hola'</Tooltip>
          <Legend></Legend>
          <Bar dataKey='weight' fill='#6b48ff'></Bar>
          <Bar dataKey='age' fill='#1ee3cf'></Bar>         
       
      </BarChart>
    </ResponsiveContainer>
  )
}
export default Elementosimplechart