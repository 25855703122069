import React from 'react'
import { Line, CartesianGrid, Legend, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
const data = [
  {name:'Sussy', age:10, weight:95},
  {name:'Tony', age:30, weight:60},
  {name:'Giovi', age:15, weight:130},
  {name:'Lei', age:2, weight:24},
]


const Elementlinechart = () => {
  return (
    <ResponsiveContainer width='100%' aspect={2}>

      <LineChart data={data} width={500} height={300} margin={{top:5, right:30,left:20,bottom:5}}>
        <CartesianGrid strokeDasharray='3 3'>  </CartesianGrid>
          <XAxis dataKey='name'></XAxis>
          <YAxis ></YAxis>
          <Tooltip>'hola'</Tooltip>
          <Legend></Legend>
          <Line type='monotone' dataKey='weight' stroke='#6b48ff' strokeWidth={3}></Line>
          <Line type='monotone' dataKey='age' stroke='#1ee3cf'></Line>     
       </LineChart>

    </ResponsiveContainer>
  )
}
export default Elementlinechart