import './App.css';
import Elementlinechart from './components/line';
import  Elementosimplechart  from './components/Simplechart';

function App() {
  return (
    <div>
      <h1 className='text-center text-2xl'>recharts</h1>
      <Elementosimplechart />
      <Elementlinechart/>
    </div>
  );
}

export default App;
